@value amesWhite from '../../common/colors.css';
@value amesDeepRoyalBlue from '../../common/colors.css';
@value amesRoyalBlue from '../../common/colors.css';
@value amesLightGray from '../../common/colors.css';
@value amesDeepBlue from '../../common/colors.css';

.headerWrapper {
  z-index: 10000;
  border: 0;
  margin-right: 0;

  left: 0;
  right: 0;
  top: 0;
  height: 11vh;
  background-color: rgba(255, 255, 255, 0.95);
}

.companyName {
  border: 0;
  left: 0;
  right: 0;
  margin-top: 1rem;
  text-align: center;
  font-size: 2.5rem;

  font-family: "Times New Roman";
}
.companyNameSub {
  border: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-family: "Times New Roman";
  font-weight: 400;
}
.companyNameSub2 {
  border: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.1rem;
  font-family: "Times New Roman";
  border-bottom: 1px solid darkgray;
}
.companyNameSub3 {
  border: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1rem;
  font-family: "Times New Roman";
}
