@value amesWhite from '../../common/colors.css';
@value amesDeepRoyalBlue from '../../common/colors.css';
@value amesRoyalBlue from '../../common/colors.css';
@value amesLightGray from '../../common/colors.css';
@value amesDeepBlue from '../../common/colors.css';

.headerWrapper{
    z-index: 10000;
    border:0;
    margin-right: 0;
    position: sticky;
    left:0;
    right: 0;
    top:0;
    height: 150px;
    background-color: rgba(255,255,255, 0.95);
}


.companyName{
    border:0;
  left:0;
  right: 0;
  margin-top: 15px;
  text-align: center;
  font-size: 40px;

  font-family: "Times New Roman";
}
.companyNameSub{
    border:0;
  left:0;
  right: 0;
  text-align: center;
  font-size: 22px;
    margin-bottom: 7px;
  font-family: "Times New Roman";
    font-weight: 400;

}
.companyNameSub2{
    border:0;
  left:0;
  right: 0;
  text-align: center;
  font-size: 17px;
  font-family: "Times New Roman";
  border-bottom: 1px solid darkgray;
}
.companyNameSub3{
    border:0;
  left:0;
  right: 0;
  text-align: center;
  font-size: 16px;
  font-family: "Times New Roman";
}


.credit{
    bottom: 0;
    height: 70px;
    background-color: white;
    border-top: solid 2px darkslategrey;
    display: flex;
    justify-content: center;
}
.creditLogo{
    width: 170px;
    height: 100%;
}
.creditTextbox{
      font-family: "Times New Roman";
    display: flex;
    justify-content: center;

}
.creditTextbox2{
      font-family: "Times New Roman";
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

}
.creditText{
    white-space: pre-line;
    color: black;
    font-family: "NanumBarunGothic";
    font-size: 19px;
    margin-top: 5px;
    font-family: "Times New Roman";
    vertical-align: middle;
    
}