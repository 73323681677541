@value makerLightGrey from '../../common/colors.css';
@value makerPink from '../../common/colors.css';

.container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input{
    display: none;
}

.button{
    width:100%;
    height:100%;

    font-weight: bold;
    cursor: pointer;
    border: 0;
    outline: 0;
    font-size: 0.8rem;
}

.button.grey{
    background-color: makerLightGrey;
}
.button.pink{
    background-color: royalblue;
}

.button:hover{
    opacity:0.8;
}

.loading{
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 3px solid makerLightGrey;
    border-top: 3px solid makerPink;
    animation: spin 1.5s linear infinite;
}

@keyframes spin{
    0%{transform: rotate(0deg);}
    100%{transform: rotate(360deg)}
    
}