@value amesWhite from '../../common/colors.css';
@value amesDeepRoyalBlue from '../../common/colors.css';
@value amesRoyalBlue from '../../common/colors.css';
@value amesLightGray from '../../common/colors.css';
@value amesDeepBlue from '../../common/colors.css';

@value --side-hover-color from '../../common/colors.css';
@value --side-open-color from '../../common/colors.css';

.top {
  display: flex;
  align-items: center;
  background-color: amesDeepRoyalBlue;
  height: 4rem;
}

.top_logo {
  font-size: 1rem;
  color: amesWhite;
  padding-left: 2rem;
  left: 1.2rem;
  border-right: amesWhite 1px solid;
  padding-right: 2rem;
}

.top_article1 {
  padding-left: 2rem;
  font-size: 1.5rem;
  color: amesWhite;
}

.top_logo img {
  height: 2rem;
}

h1 {
  font-size: 2rem;
  text-align: center;
  margin-top: 0;
  font-weight: normal;
}

h2 {
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0px 0px 0.3em 0px;
  padding-left: 0.2em;
}

h3 {
  font-size: 0.7em;
  text-align: right;
  font-weight: normal;
}

.logo {
  width: 14rem;
  position: relative;
  left: 50%;
  transform: translate(-50%);

  padding-bottom: 1rem;
  padding-right: 10px;
}

.login_box {
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login_form ul {
  margin-left: 0px;
  padding-left: 0px;
}

ul,
li {
  list-style: none;
}

.bar_id,
.bar_pw {
  width: 100%;
  height: 3rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
  background-color: amesWhite;
  border: solid 1px amesLightGray;
  text-indent: 1rem;
  font-size: 1.2rem;
}

.btn_login {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: amesDeepRoyalBlue;
  border: amesWhite 1px;
  font-size: 20px;
  color: amesWhite;
  cursor: pointer;
  padding-bottom: 0;
}

.btn_login:hover {
  background-color: amesDeepBlue;
}

.onTop {
  --main-bg: #a52a2a;
  --sec-bg: yellow;
}
.hr {
  border: 1px #ff0000;
  border-bottom: 10px;
}
.index {
  border: 0;
  left: 0;
  right: 0;
  top: 0;
}
.indexTitle {
  border: 0;
  left: 0;
  right: 0;
  margin-top: 3.5rem;
  text-align: center;
  font-size: 2.2rem;
  font-family: "Times New Roman";
}
.indexBodyText {
  margin-top: px;
  text-align: center;
  font-size: 1.1rem;
  font-family: "Times New Roman";
  white-space: pre-wrap;
  line-height: 1.6rem;
}

.indexBodyTextTitle {
  margin-top: 0px;
  text-align: center;
  font-size: 1.4rem;
  font-family: "Times New Roman";
  white-space: pre-wrap;
  font-weight: bold;
}
.indexBodyTextTitle2 {
  margin-top: 0px;
  text-align: center;
  font-size: 1.2rem;
  font-family: "Times New Roman";
  white-space: pre-wrap;
  font-weight: bold;
}
.indexBody {
  border: 0;
  margin-left: 10%;
  width: 80%;
  top: 0;
}
.gridIndex {
  border: 0;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  font-size: 15px;
}
.indexTopWrap {
  width: 100%;
}
.indexTop {
  width: 100%;
  background-color: #ffffff;
  position: relative;
}
.top1Title {
  padding-top: 20px;
  padding-left: 20px;
  margin: 0;
  right: 0;
  font-family: "NanumBarunGothic";
  font-size: 40px;
}
.top1Body {
  left: 0;
  right: 0;
  padding-left: 30%;
  text-align: left;
  font-family: "NanumBarunGothic";
  font-size: 20px;
}
.svgCircles {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  z-index: 100;
}
.joker {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 50;
}
.moreBusiness {
  padding-top: 30px;
  margin-left: 80%;
  bottom: 0;
  font-size: 14px;
  font-family: "NanumBarunGothic";
}
.indexMidWrap {
  width: 100%;
  height: 600px;
}
.indexMid {
  width: 100%;
  height: 100%;
}
.midTitle {
  font-size: 40px;
  font-family: "NanumBarunGothic";
  padding: 20px 0 0 20px;
}
.midBody {
  font-size: 20px;
  font-family: "NanumBarunGothic";
  padding: 2% 0 0 0;
  display: flex;
}
.indexBotWrap {
  width: 100%;
  height: 500px;
}
.indexBottom {
  width: 100%;
  height: 100%;
  background-color: var(--sec-bg);
}
.credit {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 100px;
  height: 200px;
  background-color: rgba(30, 39, 59, 0.8);
  display: flex;
}
.creditLogo {
  width: 10%;
  height: 100%;
  margin-left: 5%;
}
.creditText {
  position: absolute;
  color: white;
  font-family: "NanumBarunGothic";
  font-size: 11px;
  margin-top: 0;
  right: 5%;
  height: 100%;
  line-height: 200px;
  vertical-align: middle;
}

.videoWrapper {
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 (필요 시) */
  width: 100%;
}

.videoContainer {
  position: relative;
  width: 100%;
  max-width: 560px; /* 최대 크기 제한 */
  aspect-ratio: 16 / 9; /* 비율 유지 */
}

.videoContainer iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
